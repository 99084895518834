import Vue from 'vue'
import LoginContact from "@/components/contact/mi-perfil/LoginContact.vue";
import VueAWN from "vue-awesome-notifications"

Vue.use(VueAWN, {
    position: "top-right",
});

document.addEventListener('DOMContentLoaded', () => {
    const app = new Vue({
        render: h => h(LoginContact, {
            props: {}
        })
    }).$mount('#login-contact')
})