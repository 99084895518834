<template>
    <div>
        <a class="section-profile" @click="ctaProfile()">
            <img src="/public-assets/icon-profile" alt="profile" class="section-profile-icon">
        </a>
        <div v-if="showWindow">
            <LoginContactWindow @close-window="showWindow = false"/>
        </div>
    </div>
</template>

<script>
import LoginContactWindow from "@/components/contact/mi-perfil/LoginContactWindow.vue";

export default {
    components: {LoginContactWindow},
    data() {
        return {
            showWindow: false,
            logged: false,
        }
    },
    methods: {
        ctaProfile() {
            if (this.logged) {
                window.location.href = '/mi-perfil'
                return
            }

            this.showWindow = !this.showWindow
        },
    }
}
</script>