<template>
    <div class="login-window">
        <div class="login-window-header">
            <p>Mi perfil</p>
            <p class="login-window-header-close">
                <i class="fa-solid fa-x" @click="$emit('close-window')"></i>
            </p>
        </div>
        <div class="login-window-body">
            <p class="login-window-body-title">Iniciar Sesión</p>
            <label>Correo Electrónico</label>
            <input v-model="email" type="email" placeholder="Ingresa aquí tu correo electrónico">
            <span v-if="errors.email" class="login-window-body-error">Campo email requerido o formato incorrecto</span>
            <label v-if="withPassword">Contraseña</label>
            <input v-if="withPassword" v-model="password" type="password"
                   placeholder="Ingresa aquí tu contraseña">
            <span v-if="withPassword && errors.password"
                  class="login-window-body-error">Campo contraseña requerido</span>
            <p v-if="withPassword" class="login-window-body-forgot-password">¿Olvidaste tu contraseña? <a href="#">Da
                clic aquí</a></p>
            <p v-if="!withPassword" class="login-window-body-login-password" @click="withPassword = true">Ingresar con
                contraseña
            </p>
            <Recaptcha v-if="withPassword" @verifyRecaptcha="validateRecaptcha"></Recaptcha>
            <span v-if="errors.recaptcha" class="login-window-body-error">Recaptcha requerido</span>
            <span v-if="errors.serverError"
                  class="login-window-body-error">Error al iniciar sesión, favor de verificar sus datos
            </span>
            <button class="btn btn-primary btn-blue my-2" @click="signIn()">
                {{ withPassword ? 'Ingresar' : 'Enviar' }}
                <span v-if="isLoading" class="spinner-border text-light spinner-border-sm" role="status">
                    <span class="sr-only">Loading...</span>
                </span>
            </button>
            <p v-if="withPassword" class="login-window-body-login-email" @click="withPassword = false">
                Ingresar con correo electrónico
            </p>
        </div>
    </div>
</template>

<script>
import Recaptcha from "@/components/Recaptcha.vue";

export default {
    components: {Recaptcha},
    data() {
        return {
            withPassword: false,
            isLoading: false,
            email: '',
            password: '',
            errors: {
                email: false,
                password: false,
                recaptcha: false,
                serverError: false,
            },
            token: null,
            recaptcha: false
        }
    },
    methods: {
        signIn() {
            this.isLoading = true

            if (!this.validateFields()) {
                this.isLoading = false
                return
            }

            if (this.withPassword) {
                this.loginWithPassword()
            } else {
                const urlToRedirect = `${window.location.origin}/mi-perfil`
                this.sendUserMagicLink(urlToRedirect)
            }
        },
        validateFields() {
            let validate = true
            this.errors.email = false
            this.errors.password = false
            this.errors.serverError = false
            const emailRegex = /^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9-]{2,24}$/

            if (!emailRegex.test(this.email)) {
                this.errors.email = true
                validate = false
            }

            if (this.withPassword && this.password.length === 0) {
                this.errors.password = true
                validate = false
            }

            if (this.withPassword && !this.recaptcha) {
                this.errors.recaptcha = true
                validate = false
            }

            return validate
        },
        validateRecaptcha(token) {
            this.token = token
            this.recaptcha = true
        },
        loginWithPassword() {
            let data = {
                email: this.email,
                password: this.password,
                token: this.token,
            }
            this.$axios.post('/api/profile-login', data).then((response) => {
                if (response.data.status === 'success') {
                    window.location.href = '/mi-perfil'
                } else {
                    this.$awn.warning('El correo y/o la contraseña son incorrectos, por favor intenta nuevamente.', {
                        labels: {
                            warning: "Credenciales erróneas"
                        }
                    })
                }
            }).catch(e => {
                console.error(e)
                this.errors.serverError = true
            }).finally(() => {
                this.isLoading = false
            })
        },
        sendUserMagicLink(urlToRedirect) {
            let data = {
                email: this.email,
                redirect: urlToRedirect,
                template: 'profile-contact-magic-link',
            }
            this.$axios.post('/api/send-magic-link', data).then((response) => {
                if (response.data.success && response.data.total_sent > 0) {
                    let alert = this.$alerts['login-send-magic-link'].content.values
                    this.$awn.success(alert.description.value.replace('mailVar', this.email), {
                        labels: {
                            success: alert.title.value
                        }
                    })
                } else {
                    let alert = this.$alerts['login-mail-not-found'].content.values
                    this.$awn.warning(alert.description.value, {
                        labels: {
                            warning: alert.title.value
                        }
                    })
                }
            }).catch(e => {
                console.error(e)
            }).finally(() => {
                this.email = ''
                this.isLoading = false
            })
        },
    },
}
</script>

<style lang="scss" scoped>
@import 'vue-awesome-notifications/dist/styles/style.css';
</style>