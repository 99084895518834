
<div class="login-window">
    <div class="login-window-header">
        <p>Mi perfil</p>
        <p class="login-window-header-close">
            <i class="fa-solid fa-x" @click="$emit('close-window')"></i>
        </p>
    </div>
    <div class="login-window-body">
        <p class="login-window-body-title">Iniciar Sesión</p>
        <label>Correo Electrónico</label>
        <input v-model="email" type="email" placeholder="Ingresa aquí tu correo electrónico">
        <span v-if="errors.email" class="login-window-body-error">Campo email requerido o formato incorrecto</span>
        <label v-if="withPassword">Contraseña</label>
        <input v-if="withPassword" v-model="password" type="password"
               placeholder="Ingresa aquí tu contraseña">
        <span v-if="withPassword && errors.password"
              class="login-window-body-error">Campo contraseña requerido</span>
        <p v-if="withPassword" class="login-window-body-forgot-password">¿Olvidaste tu contraseña? <a href="#">Da
            clic aquí</a></p>
        <p v-if="!withPassword" class="login-window-body-login-password" @click="withPassword = true">Ingresar con
            contraseña
        </p>
        <Recaptcha v-if="withPassword" @verifyRecaptcha="validateRecaptcha"></Recaptcha>
        <span v-if="errors.recaptcha" class="login-window-body-error">Recaptcha requerido</span>
        <span v-if="errors.serverError"
              class="login-window-body-error">Error al iniciar sesión, favor de verificar sus datos
        </span>
        <button class="btn btn-primary btn-blue my-2" @click="signIn()">
            {{ withPassword ? 'Ingresar' : 'Enviar' }}
            <span v-if="isLoading" class="spinner-border text-light spinner-border-sm" role="status">
                <span class="sr-only">Loading...</span>
            </span>
        </button>
        <p v-if="withPassword" class="login-window-body-login-email" @click="withPassword = false">
            Ingresar con correo electrónico
        </p>
    </div>
</div>
